export default class Menu {
  constructor() {
    this.hamburger = $('.hamburger__wrapper');
    this.nav = $('.header__nav');

    this.childrenToggle = $(".children-toggle");

    this.initMenu();
    this.initChildrenToggle();
  }

  initMenu() {
    this.hamburger.on('click', () => {
      this.hamburger.children().toggleClass('active');
      this.nav.slideToggle(300);
    })
  }

  initChildrenToggle() {
    this.childrenToggle.on('click', (e) => {
      const $toggle = $(e.currentTarget);

      $toggle.toggleClass('open');
      $toggle.next().slideToggle(300);

      if ($toggle.hasClass('open')) {
        $toggle.attr('aria-label', 'Hide Children');
      } else {
        $toggle.attr('aria-label', 'Show Children');
      }
    });
  }
}
