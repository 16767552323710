export default class Anchors {
  constructor() {
    $(document).on('click', 'a.anchor-link', function (event) {
        if (
          location.pathname.replace(/^\//, '') ==
            this.pathname.replace(/^\//, '') &&
          location.hostname == this.hostname
        ) {
          let target = $(this.hash)
          target = target.length
            ? target
            : $('a[id=' + this.hash.slice(1) + ']')

          if (target.length) {
            event.preventDefault()

            $('html, body').animate(
              {
                scrollTop: target.offset().top - 40,
              },
              600,
              () => {
                var $target = $(target)
                $target.focus()

                if ($target.is(':focus')) {
                  return false
                } else {
                  $target.attr('tabindex', '-1')
                  $target.focus()
                }
              },
            )
          }
        }
      })

    let boardNames = document.querySelectorAll('.copy__content a[id], a[name]');
    let anchorData = [];

    Array.from(boardNames).forEach(boardName => {

      if (boardName.name) {
        anchorData.push({id: boardName.id, name: boardName.getAttribute('name')}) 
      } else {
        anchorData.push({id: boardName.id, name: $(boardName).parent().text()})
      }
    });

    if (anchorData.length) {
      $('.section-navigation').append('<ul></ul>');

      anchorData.forEach(el => {
        $('.section-navigation ul').append(`<li><a class="anchor-link" href="#${el.id}"> ${el.name}</a></li>`);
      });
    }
  }
}