export default class SwipeableCards {
  constructor() {
    $(window).on('load resize', () => {
      const categories = $('.categories')
      const windowWidth = $(window).width()

      if (windowWidth < 600 && !categories.hasClass('slick-initialized')) {
        categories.slick({
          centerMode: true,
          centerPadding: '80px',
          arrows: false,
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 380,
              settings: {
                centerPadding: '20px',
              },
            },
          ],
        })
      } else if (
        windowWidth >= 600 &&
        categories.hasClass('slick-initialized')
      ) {
        categories.slick('unslick')
      }
    })
  }
}
