import Tabulator from 'tabulator-tables'
import 'tabulator-tables/dist/css/tabulator.min.css'
import Papa from 'papaparse'

export default class DataTables {
  constructor() {
    window.loadTable = this.loadTable
  }

  loadTable(id, data) {
    Papa.parse(data, {
      download: true,
      header: true,
      complete: (parsed) => {
        let data = [...parsed.data]

        const columns = []
        Object.keys(data[0]).forEach((header) => {
          columns.push({
            field: header,
            title: header.replace(/"/g, ''),
          })
        })

        this.table = new Tabulator(id, {
          data,
          columns,
          selectable: false,
          layout: 'fitColumns',
          dataLoaded: function (data) {
            const totalRow = data.find((r) => {
              const firstKey = Object.keys(r)[0]

              if (r[firstKey].toLowerCase().trim() == 'total') {
                return true
              }
            })

            if (totalRow) {
              const totalIndex = data.indexOf(totalRow)

              const rows = this.getRows()
              var lastRow = rows[totalIndex]

              if (lastRow) {
                lastRow.freeze()
              }
            }
          },
          renderComplete: () => {
            const tableHolder = $(`${id} .tabulator-tableHolder`)

            $(window).on('load', () => {
              setUpScrolling(tableHolder)
            })

            tableHolder.on('scroll', () => {
              setUpScrolling(tableHolder)
            })

            const setUpScrolling = (target) => {
              const scrolled = target.scrollLeft()
              const contentWidth = target.find('.tabulator-table').width()
              const scrollRight = parseInt(contentWidth - target.width())
              const parent = tableHolder.parents('.data-table')

              if (scrolled > 0) {
                parent.find('.data-table__scroll--left').addClass('show')
              } else {
                parent.find('.data-table__scroll--left').removeClass('show')
              }

              if (scrolled > scrollRight) {
                parent.find('.data-table__scroll--right').removeClass('show')
              } else {
                parent.find('.data-table__scroll--right').addClass('show')
              }
            }
          },
        })
      },
    })
  }
}
