import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import objectFitVideos from 'object-fit-videos'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

objectFitImages()
objectFitVideos()

import Anchors from './anchors'
import Chart from './chart'
import DataTables from './data-tables'
import HowRadWorks from './how-rad-works'
import HowRadWorksMobile from './how-rad-works-mobile'
import Menu from './menu'
import Formbuilder from './formbuilder'
import Parallax from './parallax'
import SwipeableCards from './swipeable-cards'
import Slideshow from './slideshow'
import Video from './video'

new Chart()
new DataTables()
new HowRadWorks()
new HowRadWorksMobile()
new Menu()
new Formbuilder()
new Parallax()
new SwipeableCards()
new Slideshow()
new Video()
new Anchors()

const isIE = (userAgent) => {
  userAgent = userAgent || navigator.userAgent;
  if (userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1) {
    document.querySelector('.header__ie-container').style.cssText = "display: flex; align-items: center;";
  } else {
    document.querySelector('.header__ie-container').style.cssText = "display: none;";
  };
}

isIE();