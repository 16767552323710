import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Hammer from 'hammerjs'

gsap.registerPlugin(ScrollTrigger)

export default class HowRadWorksMobile {
  constructor() {
    $(window).on('load', () => {
      let windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth

      if ($('.how-rad-works-mobile__slides').length && windowWidth < 768) {
        $('.promo').hide()
        $('footer').hide()
      } else {
        $('.promo').show()
        $('footer').show()
      }

      $('.how-rad-works-mobile__slides').on('init', () => {
        const target = document.querySelector(
          '.how-rad-works-mobile__slides__slide-7',
        )

        var manager = new Hammer.Manager(target)

        // Create a recognizer
        var Swipe = new Hammer.Swipe()

        // Add the recognizer to the manager
        manager.add(Swipe)

        // Subscribe to a desired event
        manager.on('swipe', function (e) {
          const direction = e.offsetDirection

          if (direction === 4) {
            $('.how-rad-works-mobile__slides').slick('slickSetOption', {
              swipe: true,
            })

            $('.how-rad-works-mobile__body').fadeOut()
            $('.promo').hide()
            $('footer').hide()

            $(
              '.how-rad-works-mobile__slides__slide-7__content__invested',
            ).removeClass('slide-left')
            $(
              '.how-rad-works-mobile__slides__slide-7__content__invested__learn-more',
            ).fadeOut()
            $('.how-rad-works-mobile__slides__slide-7__pennies').removeClass(
              'zoom-out',
            )

            setTimeout(() => {
              $('.how-rad-works-mobile__slides').slick('slickPrev')
            }, 4500)
          }
        })
      })

      $('.how-rad-works-mobile__slides').slick({
        infinite: false,
      })

      $('.how-rad-works-mobile__slides').on(
        'afterChange',
        (event, slick, currentSlide, nextSlide) => {
          if (currentSlide === 1) {
            $('.how-rad-works-mobile__slides__slide-1__content p').fadeIn()
          } else if (currentSlide === 2) {
            $('.how-rad-works-mobile__slides__slide-1__content p').fadeOut()
            $(
              '.how-rad-works-mobile__slides__slide-3__content__penny span',
            ).removeClass('filled')
            this.wiggle(
              $('.how-rad-works-mobile__slides__slide-2__content svg'),
            )
          } else if (currentSlide === 3) {
            $(
              '.how-rad-works-mobile__slides__slide-3__content__penny span',
            ).addClass('filled')
          } else if (currentSlide === 4) {
            this.wiggle($('.how-rad-works-mobile__slides__slide-4 svg .wiggle'))
            $(
              '.how-rad-works-mobile__slides__slide-3__content__penny span',
            ).removeClass('filled')
            $(
              '.how-rad-works-mobile__slides__slide-5__content__penny span',
            ).removeClass('filled')
          } else if (currentSlide === 5) {
            $(
              '.how-rad-works-mobile__slides__slide-5__content__penny span',
            ).addClass('filled')
          } else if (currentSlide === 6) {
            this.wiggle($('.how-rad-works-mobile__slides__slide-6 svg .wiggle'))

            $(
              '.how-rad-works-mobile__slides__slide-5__content__penny span',
            ).removeClass('filled')
            $('.how-rad-works-mobile__body').fadeOut()
            $('.promo').hide()
            $('footer').hide()
          } else if (currentSlide === 7) {
            if (
              !$('.how-rad-works-mobile__slides__slide-7__pennies').hasClass(
                'zoom-out',
              )
            ) {
              $('.how-rad-works-mobile__slides__slide-7__pennies').addClass(
                'zoom-out',
              )
              $(
                '.how-rad-works-mobile__slides__slide-7__content__invested',
              ).addClass('slide-left')

              setTimeout(() => {
                $(
                  '.how-rad-works-mobile__slides__slide-7__content__invested__learn-more',
                ).fadeIn()
                $('.how-rad-works-mobile__body').fadeIn()
                $('.promo').show()
                $('footer').show()

                $('.how-rad-works-mobile__slides').slick('slickSetOption', {
                  swipe: false,
                })

                if ($('.how-rad-works-mobile').length) {
                  gsap.to('.how-rad-works-mobile__body__rad-logo div', {
                    width: '0',
                    scrollTrigger: {
                      trigger: '.how-rad-works-mobile__body__rad-logo',
                      start: 'bottom bottom',
                      end: '+=200',
                      scrub: true,
                    },
                  })
                }
              }, 4000)
            } else {
              $('.how-rad-works-mobile__slides__slide-7').css('height', '100%')
              $('.how-rad-works-mobile__body').fadeIn()
              $('.promo').show()
              $('footer').show()
            }
          }
        },
      )
    })
  }

  wiggle(target) {
    target
      .animate(
        { deg: 15 },
        {
          duration: 400,
          step: function (now) {
            $(this).css({ transform: 'rotate(' + now + 'deg)' })
          },
        },
      )
      .animate(
        { deg: -15 },
        {
          duration: 400,
          step: function (now) {
            $(this).css({ transform: 'rotate(' + now + 'deg)' })
          },
        },
      )
      .animate(
        { deg: 0 },
        {
          duration: 400,
          step: function (now) {
            $(this).css({ transform: 'rotate(' + now + 'deg)' })
          },
        },
      )
  }
}
