import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Parallax {
  constructor() {
    if ($('.radical-days').length) {
      gsap.to('.radical-days__background', {
        top: '-300px',
        scrollTrigger: {
          trigger: '.radical-days',
          start: 'top center',
          end: 'bottom top',
          scrub: true,
        },
      })
    }
  }
}
