import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const screenWidth = window.screen.width

export default class HowRadWorks {
  constructor() {
    if ($('.how-rad-works').length) {
      gsap.to('.how-rad-works__sun-logo', {
        x: `${screenWidth + 200}`,
        scrollTrigger: {
          trigger: '.how-rad-works__sun-logo',
          start: 'center center',
          end: `+=${screenWidth * 2}`,
          scrub: true,
          id: 'scrub',
          pin: true,
        },
      })

      gsap.to('.how-rad-works__first-half', {
        scrollTrigger: {
          trigger: '.how-rad-works__filled-penny',
          pin: true,
          start: 'center center',
        },
      })

      gsap.to('.how-rad-works__first-half', {
        scrollTrigger: {
          trigger: '.how-rad-works__filled-penny-wrapper span',
          start: 'top -200px',
          end: '+=99999',
          toggleClass: 'filled',
        },
      })

      let categoriesTop = gsap.timeline({
        scrollTrigger: {
          trigger: '.how-rad-works__categories',
          pin: true, // pin the trigger element while active
          start: 'center center', // when the top of the trigger hits the top of the viewport
          end: '+=2000', // end after scrolling 500px beyond the start
          scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        },
      })

      categoriesTop
        .from('.how-rad-works__categories > div:first-child', {
          left: 'translateX(100vw)',
          ease: 'power1.inOut',
        })
        .to('.how-rad-works__categories > div:first-child', {
          transform: 'translateX(0)',
          ease: 'power1.inOut',
        })
        .to('.how-rad-works__categories > div:first-child', {
          transform: 'translateX(-100vw)',
          ease: 'power1.inOut',
        })

      let categoriesBottom = gsap.timeline({
        scrollTrigger: {
          trigger: '.how-rad-works__categories',
          pin: true, // pin the trigger element while active
          start: 'center center', // when the top of the trigger hits the top of the viewport
          end: '+=2000', // end after scrolling 500px beyond the start
          scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
          // markers: true,
        },
      })

      categoriesBottom
        .from('.how-rad-works__categories > div:last-child', {
          transform: 'translateX(-100vw)',
          ease: 'power1.inOut',
        })
        .to('.how-rad-works__categories > div:last-child', {
          transform: 'translateX(0)',
          ease: 'power1.inOut',
        })
        .to('.how-rad-works__categories > div:last-child', {
          transform: 'translateX(100vw)',
          ease: 'power1.inOut',
        })
      
        gsap.to('.how-rad-works__other-half', {
          scrollTrigger: {
            trigger: '.how-rad-works__other-half-filled-penny',
            pin: true,
            start: 'center center',
          },
        })
  
        gsap.to('.how-rad-works__other-half', {
          scrollTrigger: {
            trigger: '.how-rad-works__other-half-filled-penny-wrapper span',
            start: 'top -200px',
            end: '+=99999',
            toggleClass: 'filled',
          },
        })
  
        let muncipalitiesTop = gsap.timeline({
          scrollTrigger: {
            trigger: '.how-rad-works__muncipalities',
            pin: true, // pin the trigger element while active
            start: 'center center', // when the top of the trigger hits the top of the viewport
            end: '+=2500', // end after scrolling 500px beyond the start
            scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
          },
        })
  
        muncipalitiesTop
          .from('.how-rad-works__muncipalities > div:first-child', {
            left: 'translateX(100vw)',
            ease: 'power1.inOut',
          })
          .to('.how-rad-works__muncipalities > div:first-child', {
            transform: 'translateX(0)',
            ease: 'power1.inOut',
          })
          .to('.how-rad-works__muncipalities > div:first-child', {
            transform: 'translateX(-100vw)',
            ease: 'power1.inOut',
          })
  
        let muncipalitiesBottom = gsap.timeline({
          scrollTrigger: {
            trigger: '.how-rad-works__muncipalities',
            pin: true, // pin the trigger element while active
            start: 'center center', // when the top of the trigger hits the top of the viewport
            end: '+=2500', // end after scrolling 500px beyond the start
            scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // markers: true,
          },
        })
  
        muncipalitiesBottom
          .from('.how-rad-works__muncipalities > div:last-child', {
            transform: 'translateX(-100vw)',
            ease: 'power1.inOut',
          })
          .to('.how-rad-works__muncipalities > div:last-child', {
            transform: 'translateX(0)',
            ease: 'power1.inOut',
          })
          .to('.how-rad-works__muncipalities > div:last-child', {
            transform: 'translateX(100vw)',
            ease: 'power1.inOut',
          })

      gsap.to('.how-rad-works__pennies', {
        backgroundSize: '80px',
        scrollTrigger: {
          trigger: '.how-rad-works__pennies',
          start: 'top top',
          end: '+=2000',
          pin: true,
          scrub: true,
        },
      })

      gsap.to('.how-rad-works__since-circle', {
        left: -60,
        scrollTrigger: {
          trigger: '.how-rad-works__since-circle',
          start: 'bottom bottom',
          end: '+=800',
          scrub: true,
          id: 'scrub',
        },
      })

      gsap.to('.how-rad-works__rad-logo div', {
        width: '0',
        scrollTrigger: {
          trigger: '.how-rad-works__rad-logo',
          start: 'bottom bottom',
          end: '+=300',
          scrub: true,
        },
      })
    }
  }
}
