import ApexCharts from 'apexcharts'
import Papa from 'papaparse'

export default class Chart {
  constructor() {
    window.loadChart = this.loadChart
  }

  loadChart(id, data) {
    Papa.parse(data, {
      download: true,
      complete: (parsed) => {
        let data = [...parsed.data]

        let headers = data[0]
        let months = []

        headers.forEach((header) => {
          if (header.match(/[a-z]/g)) {
            months.push(header)
          }
        })

        let options = {
          series: [],
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: months,
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return `$${Number(value).toLocaleString('en')}`
              },
            },
          },
          colors: ['#ee682f', '#114b89', '#37b34a', '#8c4fb7'],
        }

        const amountData = [...data]
        amountData.splice(0, 1)

        amountData.forEach((line) => {
          if (line[0]) {
            let newSeries = {
              name: line[0],
              data: [],
            }

            const amounts = [...line]
            amounts.splice(0, 1)

            amounts.forEach((amount, index) => {
              if (months[index]) {
                const amountInt = parseInt(amount.replace(/\$|,/g, ''), 10)

                if (amountInt !== 0 && !isNaN(amountInt)) {
                  const newPoint = {
                    x: months[index],
                    y: amountInt,
                  }

                  newSeries.data.push(newPoint)
                } else {
                  const newPoint = {
                    x: months[index],
                    y: null,
                  }

                  newSeries.data.push(newPoint)
                }
              }
            })

            options.series.push(newSeries)
          }
        })

        var chart = new ApexCharts(document.getElementById(id), options)
        chart.render()
      },
    })
  }
}
